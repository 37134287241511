'use client';
import { Flex, Row, Image, Col } from 'antd';
import { HomePartnerFragment } from '@/@types/generated/sitecore';
import WaveRightIcon from '../SVG/WaveRightIcon';
import WaveLeftIcon from '../SVG/WaveLeftIcon';
import { useGlobalStore } from '@/hooks/useGlobalStore';
import { WATrackerClickEvn } from '@/utils/wa';
import CustButton from '../Button';
import RichText from '../RichText';
import { withFullPath } from '@/utils/utils';

interface Props {
    content: { Item: HomePartnerFragment };
}

const HomePartner = (props: Props) => {
    // console.log('homePartner----', props);
    const { titleIconOptions, partnerBlockHeader, partnerList } = props.content.Item;
    const globalStore = useGlobalStore();

    const musicWaveColor = globalStore.currentTheme?.musicWaveColor;

    const TITLE_ICON = {
        titleIconRight: () => <WaveRightIcon iconColor={musicWaveColor}></WaveRightIcon>,
        titleIconLeft: () => <WaveLeftIcon iconColor={musicWaveColor}></WaveLeftIcon>,
    };

    const TitleIcon = titleIconOptions?.value
        ? TITLE_ICON[titleIconOptions?.value as keyof typeof TITLE_ICON]
        : () => null;

    return (
        <Flex vertical className={'px-5 my-24 w-full'}>
            <Flex vertical gap={8} align='center'>
                <RichText
                    className={'text-center text-base-font-color'}
                    html={partnerBlockHeader?.rendered || ''}
                />
                <TitleIcon />
            </Flex>
            <Row gutter={[16, 24]} justify={'center'} className={'mt-8'}>
                {partnerList.map((item, index: number) => (
                    <Col key={index} xs={12} lg={4}>
                        <CustButton
                            data={{
                                buttonLink: item?.logoLink?.value || '',
                                className: '!w-full',
                                setting: {
                                    type: 'link',
                                },
                            }}
                        >
                            <Flex
                                align={'center'}
                                justify={'center'}
                                className={'rounded border h-[80px] cursor-pointer'}
                                style={{ borderColor: 'rgba(255, 255, 255, 0.5)' }}
                                onClick={() => {
                                    WATrackerClickEvn(item?.clickEventID?.value);
                                }}
                            >
                                <Image
                                    preview={false}
                                    src={withFullPath(item?.logoImage?.src)}
                                    alt=''
                                    className={''}
                                />
                            </Flex>
                        </CustButton>
                    </Col>
                ))}
            </Row>
        </Flex>
    );
};

export default HomePartner;
