'use client';
import { HomeTestimonialFragment } from '@/@types/generated/sitecore';
import { HomeTestimonialSlider, Slider } from '../Slider';
import ImageBgSection from '../Template/ImageBgSection';
import { useGlobalStore } from '@/hooks/useGlobalStore';

interface Props {
    content: { Item: HomeTestimonialFragment };
}

const HomeTestimonial = (props: Props) => {
    // console.log('HomeTestimonial----', props);
    const { bgImage, testimonialList } = props.content.Item;
    const globalStore = useGlobalStore();

    const themeColor = globalStore.currentTheme?.themeColor;

    return (
        <ImageBgSection image={bgImage?.src} classname={'py-[64px]'}>
            <Slider
                settings={{
                    slidesPerView: 1,
                    pagination: {
                        clickable: true,
                    },
                    autoplay: {
                        delay: 10000,
                        disableOnInteraction: false,
                    },
                }}
                className='w-full'
                currentThemeColor={themeColor}
            >
                {HomeTestimonialSlider({ data: testimonialList, themeColor })}
            </Slider>
        </ImageBgSection>
    );
};

export default HomeTestimonial;
