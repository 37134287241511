import React, { memo, useEffect, useRef, useState } from 'react';
import { withFullPath } from '@/utils/utils';
import { Flex, Image } from 'antd';
import CustButton, { GeneralButtonType } from '@componentsButton';
import cn from 'classnames';
import { HomePageHeroFragment } from '@/@types/generated/sitecore';
import RichText from '../RichText';
import { WATrackerClickEvn, getButtonLinkWithCID } from '@/utils/wa';
import { useTranslation } from '@i18n/use-transition.client';
import Dialog from '../Dialog';
import VideoPlay from '../VideoIframe';

import ScrollDownENIcon from '@public/images/home/swipe-down-icon-en.svg';
import ScrollDownCHIcon from '@public/images/home/swipe-down-icon-ch.svg';
import useWindowSize from '@/utils/useScreenSize';
import { useGlobalStore } from '@/hooks/useGlobalStore';

export type SliderItemProps = {
    data: HomePageHeroFragment[];
    showModal?: any;
    size?: any;
    selectedIndex: number;
};

// eslint-disable-next-line react/display-name
const Banner = memo(
    (props: {
        data: HomePageHeroFragment[];
        selectedIndex: number;
        setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
        setCurrentCard: React.Dispatch<React.SetStateAction<HomePageHeroFragment | undefined>>;
    }) => {
        const { data, selectedIndex, setIsModalOpen, setCurrentCard } = props;
        const [videoPoster, setVideoPoster] = useState<string>();
        const { t } = useTranslation();
        const videoRefs = useRef<(HTMLVideoElement | null)[]>([]);
        const windowSize = useWindowSize();
        const globalStore = useGlobalStore();
        const TABLET_WIDTH = 768;
        const slideRatio = 'lg:pt-[41.66666667%] md:pt-[75.520833%] pt-[177.86666667%]';
        const playVideoText = t('playVideo');
        const currentSlide = data[selectedIndex];

        const setVideoRef = (index: number) => (el: HTMLVideoElement | null) => {
            if (videoRefs.current[index]) return;
            videoRefs.current[index] = el;
        };

        useEffect(() => {
            const videoElement = videoRefs?.current[selectedIndex];
            // videoRefs?.current?.forEach((item) => {
            //     item?.pause();
            // });
            if (currentSlide?.videoSrc?.value != '') {
                if (videoElement) {
                    videoElement.currentTime = 0;
                    videoElement
                        .play()
                        .catch((error) => console.error('Error playing video:', error));
                    if (videoElement?.readyState === 0) {
                        setVideoPoster(withFullPath(currentSlide?.videoPoster?.src));
                    } else {
                        setVideoPoster(undefined);
                    }
                }
            }
        }, [currentSlide]);
        return data.map((item, index) => {
            const ctaMode = item.ctaEnable?.boolValue;
            const videoMode = item.videoEnable?.boolValue;
            const backgroundLinkMode =
                item?.heroBackgroundLink?.value &&
                !item?.videoEnable?.boolValue &&
                !item?.ctaEnable?.boolValue;

            return (
                <div
                    key={index}
                    className={cn('mx-0 flex-[0_0_100%] min-w-0 relative select-none', slideRatio)}
                >
                    <div className='absolute top-0 h-full w-full max-w-[1920px]'>
                        <div className={'relative z-0 w-full h-full'}>
                            {videoMode && (
                                <>
                                    {videoPoster ? (
                                        <Image
                                            src={withFullPath(item?.videoPoster?.src)}
                                            alt={''}
                                            width={'100%'}
                                            height={'100%'}
                                            rootClassName='w-full h-full'
                                            className='object-cover'
                                            preview={false}
                                        />
                                    ) : (
                                        <video
                                            ref={setVideoRef(index)}
                                            poster={item.videoPoster?.src}
                                            muted
                                            preload='auto'
                                            autoPlay
                                            loop
                                            playsInline
                                            className={'w-full h-full object-cover'}
                                        >
                                            <source
                                                src={item.backgroundVideoSrc?.value}
                                                type='video/mp4'
                                            />
                                        </video>
                                    )}
                                </>
                            )}
                            {!videoMode && (
                                <>
                                    <CustButton
                                        data={{
                                            className: 'hover:!opacity-100 !cursor-default',
                                            style: {
                                                minWidth: '100%',
                                                height: 'inherit !important',
                                            },
                                            setting: {
                                                type: 'link',
                                            },
                                            buttonLink:
                                                (backgroundLinkMode &&
                                                    item?.heroBackgroundLink?.value) ||
                                                '',
                                        }}
                                    >
                                        {/* Handle Image for Desktop viewport */}
                                        <Image
                                            src={withFullPath(item?.heroBackground?.src)}
                                            alt={''}
                                            width={'100%'}
                                            height={'100%'}
                                            rootClassName='w-full h-full md:!block !hidden'
                                            className='object-cover'
                                            preview={false}
                                        />
                                        {/* Handle Image for Mobile viewport */}
                                        <Image
                                            src={withFullPath(item.heroMobileBackground?.src)}
                                            alt={''}
                                            width={'100%'}
                                            height={'100%'}
                                            rootClassName='w-full h-full md:!hidden !block'
                                            className='object-cover'
                                            preview={false}
                                        />
                                    </CustButton>
                                </>
                            )}
                        </div>

                        <Flex
                            className={
                                'z-10 absolute top-0 left-0 w-full h-full px-5 lg:px-20 lg:max-w-[50%] max-w-full bg-gradient-to-t lg:bg-gradient-to-r from-black to-transparent pb-36'
                            }
                        >
                            <Flex
                                gap={40}
                                vertical
                                className={
                                    'w-full h-full lg:max-w-[630px] max-w-full justify-end lg:justify-center'
                                }
                            >
                                <Flex vertical gap={32} align={'flex-start'}>
                                    <Flex vertical gap={16} align={'flex-start'}>
                                        {!backgroundLinkMode && (
                                            <RichText html={item.heroTitle?.rendered || ''} />
                                        )}
                                    </Flex>
                                    <div className={'flex flex-wrap items-center gap-6 lg:gap-8'}>
                                        {ctaMode && (
                                            <CustButton
                                                data={{
                                                    setting: {
                                                        onClick: () => {
                                                            WATrackerClickEvn(
                                                                'RnE/HW_ClickBanner_Header-Banner',
                                                            );
                                                        },
                                                    },
                                                    buttonLink: getButtonLinkWithCID(
                                                        item.ctaLink?.value,
                                                        'Home_Header-Banner',
                                                    ),
                                                    className: 'w-fit text-base-font-color',
                                                }}
                                            >
                                                <GeneralButtonType
                                                    label={item.ctaText?.value || ''}
                                                    icon={'arrowRightBK'}
                                                    // iconColor={themeColor}
                                                />
                                            </CustButton>
                                        )}
                                        {videoMode && (
                                            <div className='basis-full lg:basis-auto'>
                                                <CustButton
                                                    data={{
                                                        setting: {
                                                            type: 'link',
                                                            onClick: () => {
                                                                setIsModalOpen(true);
                                                                setCurrentCard(item);
                                                            },
                                                        },
                                                    }}
                                                >
                                                    {GeneralButtonType({
                                                        label: playVideoText,
                                                        icon: 'playIcon',
                                                        fontColor: '#FFFFFF',
                                                    })}
                                                </CustButton>
                                            </div>
                                        )}
                                    </div>
                                </Flex>
                                {windowSize.width < TABLET_WIDTH && (
                                    <div className='absolute right-5'>
                                        {globalStore.lang === 'en-US' ? (
                                            <ScrollDownENIcon />
                                        ) : (
                                            <ScrollDownCHIcon />
                                        )}
                                    </div>
                                )}
                            </Flex>
                        </Flex>
                    </div>
                </div>
            );
        });
    },
    (prevProps, nextProps) => {
        return (
            prevProps.selectedIndex === nextProps.selectedIndex || prevProps.data === nextProps.data
        );
    },
);

// eslint-disable-next-line react/display-name
const HomeHeroContent = memo(
    ({ data, selectedIndex }: SliderItemProps) => {
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [currentCard, setCurrentCard] = useState<HomePageHeroFragment>();

        const handleCancel = () => {
            setIsModalOpen(false);
        };

        return (
            <>
                <Banner
                    data={data}
                    selectedIndex={selectedIndex}
                    setIsModalOpen={setIsModalOpen}
                    setCurrentCard={setCurrentCard}
                />
                <Dialog
                    data={{
                        setting: {
                            open: isModalOpen,
                            onCancel: handleCancel,
                            width: 800,
                            destroyOnClose: true,
                        },
                    }}
                >
                    <VideoPlay
                        className={'mt-6'}
                        localHref={typeof window !== 'undefined' ? window.location.href : ''}
                        videoLink={currentCard?.videoSrc?.value}
                        videoAutoPlay={true}
                        videoPoster={currentCard?.videoPoster?.src}
                    />
                </Dialog>
            </>
        );
    },
    (prevProps, nextProps) => {
        return (
            prevProps.selectedIndex === nextProps.selectedIndex || prevProps.data === nextProps.data
        );
    },
);

export default HomeHeroContent;
