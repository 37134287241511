'use client';
import useWindowSize from '@/utils/useScreenSize';
import { ImagePreviewGroup } from '../ImagePreview';
import { AlbumSimpleImageSlider, Slider, SliderControlButton } from '../Slider';
import { mobileBreakPoint } from '@/utils/utils';
import FormItem from 'antd/es/form/FormItem';

interface Props {
    content: { Item: any };
    slidesPerView?: number;
    data?: {
        partyDateFolder: any;
        partyDetail: any;
        partyInformation: any;
    };
}
/** 适用于 Home-partyAlbum  */
const PartyHomeAlbum = (props: Props) => {
    // console.log('partyAlbum----', props);
    const windowSize = useWindowSize();
    const isMobile = windowSize.width < mobileBreakPoint;
    const sliderSetting = !isMobile
        ? {
              initialSlide: 1,
              slidesPerView: 5,
              spaceBetween: 0,
              centeredSlides: false,
          }
        : {
              initialSlide: 1,
              slidesPerView: props.slidesPerView || 1.5,
              spaceBetween: 10,
              centeredSlides: true,
          };
    const slideTo = isMobile && props.content?.Item?.albumList.length > 3 ? 1 : 0;
    const imageList = (props.content?.Item?.albumList || []).map((item: any, index: number) => {
        return {
            src: item.contentImage?.src,
            mediaType: item.mediaType?.value,
            videoSrc: item.videoSrc?.value,
            mediaCaption: item.mediaCaption?.value,
            id: index,
        };
    });
    return (
        <ImagePreviewGroup
            imageList={imageList}
            total={(props.content?.Item?.albumList || []).length}
        >
            <Slider settings={sliderSetting} className='w-full my-5' slideTo={slideTo}>
                {AlbumSimpleImageSlider(props.content?.Item?.albumList || [])}
                <SliderControlButton className='mt-[1px] mb-[28px] max-xmd:mt-6' />
            </Slider>
        </ImagePreviewGroup>
    );
};

export default PartyHomeAlbum;
