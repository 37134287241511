'use client';

import { useState, useEffect, useRef } from 'react';

type Size = {
    width: number;
    height: number;
};

function useElementSize(): [Size, React.LegacyRef<HTMLDivElement>] {
    const ref = useRef<HTMLDivElement>(null);
    const [size, setSize] = useState({
        width: 0,
        height: 0,
    });

    const handleResize = () => {
        if (ref.current) {
            setSize({
                width: ref.current.offsetWidth,
                height: ref.current.offsetHeight,
            });
        }
    };
    useEffect(() => {
        window.addEventListener('resize', handleResize);

        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return [size, ref];
}

export default useElementSize;
