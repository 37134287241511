'use client';
import { Flex, Row, Col } from 'antd';
import cn from 'classnames';
import Heading from '../Heading';
import CustButton, { GeneralButtonType } from '../Button';
import { HomeInstagramFragment } from '@/@types/generated/sitecore';
import IGIcon from '@public/images/home/ig_icon.svg';
import useWindowSize from '@/utils/useScreenSize';
import { useGlobalStore } from '@/hooks/useGlobalStore';
import WaveRightIcon from '../SVG/WaveRightIcon';
import WaveLeftIcon from '../SVG/WaveLeftIcon';
import { WATrackerClickEvn } from '@/utils/wa';
import { mobileBreakPoint, withFullPath } from '@/utils/utils';
import RichText from '../RichText';
import { useTranslation } from '@/i18n/use-transition.client';
import styles from './index.module.scss';

interface Props {
    content: { Item: HomeInstagramFragment };
}

const HomeInstagram = (props: Props) => {
    // console.log('HomeInstagram===============', props);
    const windowSize = useWindowSize();
    const globalStore = useGlobalStore();
    const { t } = useTranslation();

    const { titleIconOptions, igBlockBgImage, igBlockContent, igBlockHeader, instagramList } =
        props.content.Item;

    const themeColor = globalStore.currentTheme?.themeColor;
    const musicWaveColor = globalStore.currentTheme?.musicWaveColor;
    const h2Color = globalStore.currentTheme?.h2Color;

    const dynamicStyle: any = {
        '--scrollbar-thumb-color': themeColor,
    };

    const TITLE_ICON = {
        titleIconRight: () => <WaveRightIcon iconColor={musicWaveColor}></WaveRightIcon>,
        titleIconLeft: () => <WaveLeftIcon iconColor={musicWaveColor}></WaveLeftIcon>,
    };

    const TitleIcon = titleIconOptions?.value
        ? TITLE_ICON[titleIconOptions?.value as keyof typeof TITLE_ICON]
        : () => null;

    return (
        <Flex
            vertical
            className={cn('pt-16 pb-0 xmd:py-24 w-full', styles.instagramWrap)}
            align={'center'}
            style={{
                backgroundImage: `url(${withFullPath(igBlockBgImage?.src)})`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                ...dynamicStyle,
            }}
        >
            <Flex vertical gap={8} align={'center'}>
                <Heading
                    className={'text-center !text-base-font-color'}
                    level={3}
                    text={igBlockHeader?.value}
                />
                <TitleIcon></TitleIcon>
            </Flex>
            <Flex gap={10} className={'mt-6'} align={'center'}>
                <IGIcon />
                <RichText
                    className='text-center text-base-font-color'
                    html={igBlockContent?.rendered || ''}
                />
            </Flex>
            <div
                className={
                    'w-full grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-5 gap-x-[10px] gap-y-[10px] items-center justify-center mt-8'
                }
            >
                {instagramList.map((item, index) => {
                    return (
                        // <Col key={index} xs={12} lg={6}>
                        <div
                            key={index}
                            className='aspect-square relative'
                            style={{
                                backgroundImage: `url(${withFullPath(item.igPostImage?.src)})`,
                                backgroundRepeat: 'no-repeat',
                                backgroundPosition: 'center',
                                backgroundSize: 'cover',
                            }}
                        >
                            <Flex
                                className={`absolute h-full w-full overflow-hidden bg-primary-bg-color/20 bg-fixed opacity-0 transition duration-300 ease-in-out hover:opacity-100 `}
                            >
                                <Flex
                                    vertical
                                    justify={'center'}
                                    align={'center'}
                                    className='aspect-square bg-primary-bg-color h-full w-full p-5 border-2 border-solid border-[#EAE219]'
                                    style={{ borderColor: themeColor }}
                                >
                                    <Flex align={'center'} className={'h-full'}>
                                        <Flex
                                            vertical
                                            align={'center'}
                                            gap={16}
                                            justify={'center'}
                                            className='h-full'
                                        >
                                            <Flex
                                                vertical
                                                gap={16}
                                                className={'px-5 overflow-y-auto'}
                                            >
                                                <Heading
                                                    level={5}
                                                    text={item.igPostTitle?.value}
                                                    style={{ color: h2Color }}
                                                />
                                                <RichText
                                                    html={item.igPostContent?.rendered || ''}
                                                />
                                            </Flex>
                                            <CustButton
                                                data={{
                                                    setting: {
                                                        type: 'link',
                                                        onClick: () => {
                                                            WATrackerClickEvn(
                                                                'RnE/HW_Click-FollowInstagram_Larger-Photo-Grid',
                                                            );
                                                        },
                                                    },
                                                    buttonLink: item.igPostLink?.value,
                                                }}
                                            >
                                                <GeneralButtonType
                                                    label={t('homeIGViewPost')}
                                                    icon='arrowRightPrimary'
                                                    className='!text-base-font-color'
                                                    // iconColor={themeColor}
                                                />
                                            </CustButton>
                                        </Flex>
                                    </Flex>
                                </Flex>
                            </Flex>
                        </div>
                        // </Col>
                    );
                })}
            </div>
            {/* <Row gutter={[10, 10]} justify={'center'} className={'mt-8'}> */}
            {/* </Row> */}
        </Flex>
    );
};

export default HomeInstagram;
