import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { EmblaOptionsType } from 'embla-carousel';
import useEmblaCarousel from 'embla-carousel-react';
import HomeHeroThumbsButton from './HomeHeroThumbsButton';
import HomeHeroContent, { SliderItemProps } from './HomeHeroContent';
import useElementSize from '@utils/useElementSize';
import { HomepageHeroBlockQueryResult } from '@/@types/generated/sitecore';

type HomepageHeroProps = {
    content: HomepageHeroBlockQueryResult;
};

const options = { loop: true };

const HomepageHero = ({ content }: HomepageHeroProps) => {
    const autoplayIntervalRef = useRef<NodeJS.Timeout | null>(null);
    const [size, sliderWrapperRef] = useElementSize();
    const thumbsWrapperRef = useRef<HTMLDivElement | null>(null);
    const [thumbsWidth, setThumbsWidth] = useState(0);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [emblaMainRef, emblaMainApi] = useEmblaCarousel(options);
    const [thumbsOptions, setThumbsOptions] = useState<EmblaOptionsType>({
        align: 'center',
        dragFree: true,
        active: false,
    });
    const [emblaThumbsRef, emblaThumbsApi] = useEmblaCarousel(thumbsOptions);
    const data = (content.Item?.list || []).filter((i) => i.enable?.boolValue);

    // handle thumb click
    const onThumbClick = useCallback(
        (index: number) => {
            if (!emblaMainApi || !emblaThumbsApi) return;
            emblaMainApi.scrollTo(index);
        },
        [emblaMainApi, emblaThumbsApi],
    );

    // handle active thumb
    const handleActiveThumbs = useCallback((isActive: boolean) => {
        setThumbsOptions((currentOptions) => ({
            ...currentOptions,
            active: isActive,
        }));
    }, []);

    // handle auto play and progress bar
    const startAutoPlay = useCallback(
        (autoplayIntervalTime: number) => {
            if (!emblaMainApi) return;

            if (autoplayIntervalRef.current) clearInterval(autoplayIntervalRef.current);
            autoplayIntervalRef.current = setInterval(() => {
                emblaMainApi.scrollNext();
            }, autoplayIntervalTime);
        },
        [emblaMainApi],
    );

    // handle select on main slide
    const onSelect = useCallback(() => {
        if (!emblaMainApi || !emblaThumbsApi) return;
        const currentSlideIndex = emblaMainApi.selectedScrollSnap();
        const currentCard = data[currentSlideIndex];
        setSelectedIndex(currentSlideIndex);
        emblaThumbsApi.scrollTo(currentSlideIndex);
        const cardDuration = currentCard?.cardDuration?.value
            ? (Number(currentCard?.cardDuration?.value) || 4) * 1000
            : currentCard.videoEnable?.boolValue
              ? 15000
              : 4000;
        startAutoPlay(cardDuration);
    }, [content.Item?.list, emblaMainApi, emblaThumbsApi, startAutoPlay]);

    // handle main slide
    useEffect(() => {
        if (!emblaMainApi) return;

        emblaMainApi.on('init', onSelect).on('select', onSelect);

        return () => {
            if (emblaMainApi) {
                emblaMainApi
                    .off('select', onSelect)
                    .off('reInit', onSelect)
            }
        };
    }, [
        emblaMainApi,
        onSelect,
    ]);

    // handle thumb width on page load
    useEffect(() => {
        setThumbsWidth(thumbsWrapperRef.current?.clientWidth || 0);
    }, []);

    // handle thumb style based on screen width
    useEffect(() => {
        if (size.width < thumbsWidth) {
            if (thumbsWrapperRef.current) {
                thumbsWrapperRef.current.style.width = 'auto';
            }
            handleActiveThumbs(true);
        } else {
            if (thumbsWrapperRef.current) {
                thumbsWrapperRef.current.style.width = 'fit-content';
            }
            handleActiveThumbs(false);
        }
    }, [handleActiveThumbs, size.width, thumbsWidth]);

    const Content = useMemo(
        () => (
            <HomeHeroContent
                data={data}
                showModal={false}
                size={size}
                selectedIndex={selectedIndex}
            />
        ),
        [data, selectedIndex],
    );

    return (
        <div className={'relative m-auto mt-[84px] xl:mt-[100px] w-full'} ref={sliderWrapperRef}>
            <div className={'overflow-hidden'} ref={emblaMainRef}>
                <div className={'flex touch-pan-y'}>{Content}</div>
            </div>
            <div
                className={'absolute bottom-8 left-0 right-0 w-fit my-0 px-0 lg:mx-20'}
                ref={thumbsWrapperRef}
            >
                <div className={'overflow-hidden'} ref={emblaThumbsRef}>
                    <div className={'flex flex-row -ml-1'}>
                        {data.length > 1 &&
                            data.map((item, index) => (
                                <HomeHeroThumbsButton
                                    key={index}
                                    onClick={() => onThumbClick(index)}
                                    selected={index === selectedIndex}
                                    selectedIndex={selectedIndex}
                                    item={{
                                        navigatorTitle: item?.cardTitle?.value,
                                        category: item?.cardCategory?.value,
                                        duration: item?.cardDuration?.value
                                            ? (Number(item?.cardDuration?.value) || 4) * 1000
                                            : item.videoEnable?.boolValue
                                              ? 15000
                                              : 4000,
                                    }}
                                />
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HomepageHero;
