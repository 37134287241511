'use client';
import { useEffect, useState } from 'react';
import { Flex } from 'antd';
import HomePartner from '@/components/HomePartner';
import HomeTestimonial from '@/components/HomeTestimonial';
import HomeThree from '@/components/HomeThree';
import HomePageContentSlider from '@/components/HomePageContentSlider';
import HomeInstagram from '@/components/HomeInstagram';
import HomepageHeroBlock from '@/components/HomepageHeroBlock';
import HomePageHero from '@/components/HomePageHero';
import HomePartyPromotion from '@/components/HomePartyPromotion';
import { WATrackerTrackPageView } from '@/utils/wa';
import PartyHomeAlbum from '@/components/PartyHomeAlbum';
import { useGlobalStore } from '@/hooks/useGlobalStore';

type TemplateTypes =
    | 'HomepageHeroBlock'
    | 'PartyInfoBlock'
    | 'PartyAlbumBlock'
    | 'ThreeBlocks'
    | 'TestimonialBlock'
    | 'PartnerBlock'
    | 'HomepageIgContainer'
    | 'HomepageContentSlider';

const withoutEnabled: TemplateTypes[] = [
    'HomepageHeroBlock',
    'PartyInfoBlock',
    'PartyAlbumBlock',
    'ThreeBlocks',
    'TestimonialBlock',
    'PartnerBlock',
    'HomepageIgContainer',
    'HomepageContentSlider',
];

const components: any = {
    // HomepageHeroBlock: HomepageHeroBlock,
    // HomepageHeroBlock: HomePageHero,
    PartyInfoBlock: HomePartyPromotion,
    PartyAlbumBlock: PartyHomeAlbum,
    ThreeBlocks: HomeThree,
    TestimonialBlock: HomeTestimonial,
    PartnerBlock: HomePartner,
    HomepageIgContainer: HomeInstagram,
    HomepageContentSlider: HomePageContentSlider,
};

interface Props {
    data: any;
    pageEventId?: string;
}

const HomePage = (props: Props) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const globalStore = useGlobalStore();

    useEffect(() => {
        setIsLoaded(true);
    }, []);

    // WA
    useEffect(() => {
        WATrackerTrackPageView(`RnE/HW/${props.pageEventId || 'Home'}`);
        globalStore.setGlobalStore({ pageID: props.pageEventId || 'Home' });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getCludesName = (all: TemplateTypes[], templateName: string) => {
        const getVal = Array.from(all).filter((i) => templateName === i);
        return getVal[0] || 'default';
    };

    const heroBlock = props.data.find((i: any) => i?.Item?.template?.name === 'HomepageHeroBlock');

    return (
        <>
            <>
                {heroBlock && (
                    <Flex
                        justify='center'
                        align='center'
                        className={'bg-primary-bg-color max-w-[1920px] w-full'}
                    >
                        <HomePageHero content={heroBlock} />
                    </Flex>
                )}
                <Flex
                    justify='center'
                    align='center'
                    className={'bg-primary-bg-color max-w-[1920px] w-full'}
                >
                    <div className='w-full'>
                        {props.data.map((content: any, index: number) => {
                            const templateName = content?.Item.template?.name || 'default';
                            const cludesName = getCludesName(withoutEnabled, templateName);
                            const enabled = templateName.includes(cludesName);
                            const Component = components[cludesName];
                            return enabled && Component ? (
                                <Flex key={index}>
                                    <Component content={content} />
                                </Flex>
                            ) : (
                                ''
                            );
                        })}
                    </div>
                </Flex>
            </>

            <div className={'px-5 xmd:px-20'}>
                <hr className={'border-content-divide-color w-full'} />
            </div>
        </>
    );
};

export default HomePage;
