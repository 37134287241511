import { Flex } from 'antd';
import React, { useEffect, useState } from 'react';
import cn from 'classnames';
import Text from '@components/Text';
import Heading from '@components/Heading';
import { useGlobalStore } from '@/hooks/useGlobalStore';

type HomeHeroThumbsButtonProps = {
    selected: boolean;
    selectedIndex: number;
    onClick: () => void;
    item: {
        category?: string;
        navigatorTitle?: string;
        duration?: number;
    };
};

let interval: NodeJS.Timeout;

const HomeHeroThumbsButton = ({
    selected,
    onClick,
    item,
    selectedIndex,
}: HomeHeroThumbsButtonProps) => {
    const [progress, setProgress] = useState(0);
    const globalStore = useGlobalStore();
    const currentTheme = globalStore.currentTheme;

    const clearProgress = () => {
        if (!interval) return;
        clearInterval(interval);
        setProgress(0);
    };

    const calcProgress = () => {
        if (!item.duration) return;
        interval = setInterval(() => {
            setProgress((prev) => {
                return (prev + 1) % 100;
            });
        }, item.duration / 100);
    };

    useEffect(() => {
        if (!selected) return;
        clearProgress();
        calcProgress();
    }, [selected, selectedIndex]);
    return (
        <Flex
            vertical
            gap={4}
            className={cn(
                'w-[244px] flex-[0_0_244px] h-full min-w-0 relative pl-1',
                selected ? 'opacity-100' : 'opacity-50',
            )}
        >
            <div
                className={cn(
                    'relative flex flex-row items-center justify-start',
                    !selected && 'opacity-0',
                )}
            >
                <div
                    className={cn(
                        'absolute left-0 right-0 h-[2px] bg-white opacity-0',
                        selected && 'opacity-50',
                    )}
                ></div>
                <div
                    className='h-[2px] bg-[#FECF13]'
                    style={{ width: `${progress}%`, background: currentTheme?.themeColor }}
                />
            </div>
            <Flex
                vertical
                className={
                    'gap-2 bg-primary-bg-color p-3 rounded select-none appearance-none touch-manipulation inline-flex cursor-pointer items-center justify-center '
                }
                onClick={onClick}
            >
                <Text
                    level={'body3'}
                    text={item.category}
                    className='text-[#FECF13] line-clamp-1 font-bold'
                    style={{ color: currentTheme?.themeColor }}
                />
                <Heading level={5} text={item.navigatorTitle} className='line-clamp-1' />
            </Flex>
        </Flex>
    );
};

export default HomeHeroThumbsButton;
