'use client';
import { Flex, Row, Col } from 'antd';
import Heading from '../Heading';
import { HomeThreeFragment } from '@/@types/generated/sitecore';
import { useGlobalStore } from '@/hooks/useGlobalStore';
import style from './index.module.scss';
import cn from 'classnames';
import RichText from '../RichText';
import { mobileBreakPoint, withFullPath } from '@/utils/utils';
import { useState } from 'react';
// import { isDesktop, isMobile } from 'react-device-detect';
import { CloseOutlined } from '@ant-design/icons';
import CustButton, { GeneralButtonType } from '../Button';
import useWindowSize from '@/utils/useScreenSize';
import { WATrackerClickEvn, getButtonLinkWithCID } from '@/utils/wa';
import { isMobile as isMobileDevice, isTablet } from 'react-device-detect';
interface Props {
    content: { Item: HomeThreeFragment };
}

type CardItemType = {
    name: string;
    title: string | undefined;
    blockContent: string | undefined;
    image: string | undefined;
    buttonLabel: string | undefined;
    buttonLink: string | undefined;
};

const MoreDetail = ({ item }: { item: CardItemType }) => (
    <CustButton
        data={{
            setting: {
                type: 'link',
                onClick: () => {
                    WATrackerClickEvn('RnE/HW_ClickBanner_3Grid-Flip-Card');
                },
            },
            buttonLink: getButtonLinkWithCID(item.buttonLink || '', 'Home_3Grid-Flip-Card'),
        }}
    >
        {GeneralButtonType({
            label: item.buttonLabel || '',
            icon: 'arrowRightPrimary',
            fontColor: '#FFFFFF',
        })}
    </CustButton>
);

const HomeThree = (props: Props) => {
    // console.log('threeData-------', props);

    const globalStore = useGlobalStore();
    // const isMobile = useWindowSize().width < mobileBreakPoint;
    const isMobile = useWindowSize().width < mobileBreakPoint || isTablet || isMobileDevice;

    const [curCard, setCurCard] = useState<string[]>([]);

    const themeColor = globalStore.currentTheme?.themeColor;
    const h2Color = globalStore.currentTheme?.h2Color;

    const dynamicStyle: any = {
        '--scrollbar-thumb-color': themeColor || 'auto',
    };

    const {
        blockATitle,
        blockAContent,
        blockAImage,
        blockBTitle,
        blockBContent,
        blockBImage,
        blockCTitle,
        blockCContent,
        blockCImage,
        blockACtaButtonText,
        blockACtaButtonLink,
        blockBCtaButtonText,
        blockBCtaButtonLink,
        blockCCtaButtonText,
        blockCCtaButtonLink,
    } = props.content.Item;

    const cardList = [
        {
            name: 'A',
            title: blockATitle?.value,
            blockContent: blockAContent?.rendered,
            image: blockAImage?.src,
            buttonLabel: blockACtaButtonText?.value,
            buttonLink: blockACtaButtonLink?.value,
        },
        {
            name: 'B',
            title: blockBTitle?.value,
            blockContent: blockBContent?.rendered,
            image: blockBImage?.src,
            buttonLabel: blockBCtaButtonText?.value,
            buttonLink: blockBCtaButtonLink?.value,
        },
        {
            name: 'C',
            title: blockCTitle?.value,
            blockContent: blockCContent?.rendered,
            image: blockCImage?.src,
            buttonLabel: blockCCtaButtonText?.value,
            buttonLink: blockCCtaButtonLink?.value,
        },
    ];

    const isclud = (i: string) => {
        return curCard.includes(i);
    };

    return (
        <Row gutter={3} className=''>
            {cardList.map((item, index) => (
                <Col key={index} xs={24} lg={8}>
                    <div
                        className='rounded relative aspect-[4/3]'
                        style={{
                            backgroundImage: `url(${withFullPath(item?.image)})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundSize: 'cover',
                        }}
                    >
                        <div
                            className={cn(
                                'rounded absolute bottom-0 h-auto w-full bg-gradient-to-t from-[#000] to-[rgba(0, 0, 0, 0.00)] px-5 py-6 z-0',
                                // isMobile && !isclud(item.name) && '!z-10',
                            )}
                        >
                            <Flex vertical gap={8}>
                                <Heading
                                    className={'!text-base-font-color'}
                                    level={5}
                                    text={item?.title}
                                />
                                {isMobile && item.buttonLabel && <MoreDetail item={item} />}
                            </Flex>
                        </div>
                        <Flex
                            onClick={() => {
                                if (isMobile) {
                                    !isclud(item.name) && setCurCard([...curCard, item.name]);
                                }
                            }}
                            className={cn(
                                isclud(item.name)
                                    ? 'transition opacity-100 duration-300 ease-in-out'
                                    : 'transition opacity-0 duration-300 ease-in-out',
                                !isMobile ? 'hover:opacity-100' : '',
                                'relative h-full w-full overflow-hidden bg-primary-bg-color/20 bg-fixed',
                            )}
                        >
                            <Flex
                                vertical
                                justify={'center'}
                                align={'center'}
                                className={cn(
                                    isMobile ? (isclud(item.name) ? 'visible' : 'invisible') : '',
                                    'aspect-[4/3] rounded bg-primary-bg-color h-full w-full p-5 border-2 border-solid border-[#EAE219]',
                                )}
                                style={{ borderColor: themeColor }}
                            >
                                <Flex align={'center'} className={'h-full w-full'}>
                                    <Flex vertical gap={16} className={'px-5'}>
                                        <Heading
                                            level={5}
                                            text={item.title}
                                            style={{ color: h2Color }}
                                        />
                                        <div
                                            className={cn(
                                                ` text-base-font-color `,
                                                style.homeThreeItem,
                                            )}
                                            style={dynamicStyle}
                                        >
                                            <RichText
                                                className={cn(
                                                    'text-[16px] 2xl:max-h-[300px] lg:max-h-[180px] xmd:max-h-[140px] sm:max-h-[400px] xs:max-h-[200px] pr-[10px] overflow-y-auto',
                                                )}
                                                style={{
                                                    wordBreak: 'break-word',
                                                    lineHeight: '27.2px',
                                                }}
                                                html={item.blockContent || ''}
                                            />
                                        </div>
                                        {!isMobile && item.buttonLabel && (
                                            <MoreDetail item={item} />
                                        )}
                                    </Flex>
                                </Flex>
                            </Flex>
                        </Flex>
                        <div
                            onClick={() => {
                                if (isMobile && isclud(item.name)) {
                                    const filteredArray = curCard.filter(
                                        (itemData) => itemData !== item.name,
                                    );
                                    isclud(item.name) && setCurCard(filteredArray);
                                }
                            }}
                            className={cn(
                                isclud(item.name)
                                    ? 'transition duration-300 ease-in-out visible'
                                    : 'transition duration-300 ease-in-out invisible',
                                !isMobile ? 'hidden' : '',
                                'absolute top-4 right-4 cursor-pointer',
                            )}
                        >
                            <CloseOutlined style={{ fontSize: 28 }} />
                        </div>
                    </div>
                </Col>
            ))}
        </Row>
    );
};

export default HomeThree;
