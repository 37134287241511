'use client';
import { Flex, Row, Image, Col } from 'antd';
import { Slide, Slider } from '../Slider';
import Heading from '../Heading';
import CustButton, { GeneralButtonType } from '../Button';
import { HomepageContentSliderFragment } from '@/@types/generated/sitecore';
import { useGlobalStore } from '@/hooks/useGlobalStore';
import WaveRightIcon from '../SVG/WaveRightIcon';
import WaveLeftIcon from '../SVG/WaveLeftIcon';
import RichText from '../RichText';
import { withFullPath } from '@/utils/utils';
import { WATrackerClickEvn, getButtonLinkWithCID } from '@/utils/wa';

interface Props {
    content: { Item: HomepageContentSliderFragment };
}

const HomePageContentSlider = (props: Props) => {
    // console.log('HomePageContentSlider===============', props);
    const globalStore = useGlobalStore();

    const themeColor = globalStore.currentTheme?.themeColor;
    const musicWaveColor = globalStore.currentTheme?.musicWaveColor;

    const TitleIcon = (i: string) => {
        return i === 'titleIconLeft' ? (
            <WaveLeftIcon iconColor={musicWaveColor}></WaveLeftIcon>
        ) : (
            <WaveRightIcon iconColor={musicWaveColor}></WaveRightIcon>
        );
    };

    const { pageContentSliderList } = props.content.Item;

    return (
        <Flex
            vertical
            justify={'center'}
            align={'center'}
            className={'bg-primary-bg-color max-w-[1920px] w-full px-10 xmd:px-20'}
        >
            <Slider
                settings={{
                    slidesPerView: 1,
                    pagination: {
                        clickable: true,
                    },
                    autoplay: {
                        delay: 10000,
                        disableOnInteraction: false,
                    },
                }}
                className='w-full my-16 xmd:my-20'
                currentThemeColor={themeColor}
            >
                {pageContentSliderList.map((item, index: number) => (
                    <Slide key={index}>
                        {item.layoutType?.value === 'LeftMediaRightText' ? (
                            <Row gutter={60} align={'middle'} className={'pb-12'}>
                                <Col xs={24} lg={12}>
                                    <Flex vertical className={'h-full'}>
                                        <Image
                                            className='rounded-[5px]'
                                            preview={false}
                                            src={withFullPath(item.contentImage?.src)}
                                            alt=''
                                        />
                                    </Flex>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <Flex
                                        vertical
                                        gap={24}
                                        justify={'center'}
                                        align={'start'}
                                        className={'py-10'}
                                    >
                                        <Flex vertical gap={8}>
                                            <Heading
                                                className={'!text-base-font-color'}
                                                level={5}
                                                text={item.contentSubHeader?.value}
                                            />
                                            {TitleIcon(item.titleIconOptions?.value || '')}
                                            <RichText html={item.contentHeader?.rendered || ''} />
                                        </Flex>
                                        <Flex vertical>
                                            <RichText html={item.contentText?.rendered || ''} />
                                        </Flex>
                                        {item.contentCtaEnable?.boolValue && (
                                            <CustButton
                                                data={{
                                                    buttonLink: getButtonLinkWithCID(
                                                        item.contentCtaLink?.value,
                                                        globalStore.pageID,
                                                    ),
                                                    setting: {
                                                        onClick: () => {
                                                            WATrackerClickEvn(
                                                                'RnE/HW_ClickBanner_Feature-Highlight',
                                                            );
                                                        },
                                                    },
                                                }}
                                            >
                                                <GeneralButtonType
                                                    label={item.contentCtaText?.value}
                                                    icon='arrowRightBK'
                                                    // iconColor={themeColor}
                                                />
                                            </CustButton>
                                        )}
                                    </Flex>
                                </Col>
                            </Row>
                        ) : (
                            <Row gutter={60} align={'middle'} className={'pb-12'}>
                                <Col xs={24} lg={12}>
                                    <Flex
                                        vertical
                                        gap={24}
                                        justify={'center'}
                                        align={'start'}
                                        className={'py-10'}
                                    >
                                        <Flex vertical gap={8}>
                                            <Heading
                                                className={'!text-base-font-color'}
                                                level={5}
                                                text={item.contentSubHeader?.value}
                                            />
                                            {TitleIcon(item.titleIconOptions?.value || '')}
                                            <RichText html={item.contentHeader?.rendered || ''} />
                                        </Flex>
                                        <Flex vertical>
                                            <RichText html={item.contentText?.rendered || ''} />
                                        </Flex>
                                        {item.contentCtaEnable?.boolValue && (
                                            <CustButton
                                                data={{
                                                    buttonLink: getButtonLinkWithCID(
                                                        item.contentCtaLink?.value,
                                                        globalStore.pageID,
                                                    ),
                                                    setting: {
                                                        onClick: () => {
                                                            WATrackerClickEvn(
                                                                'RnE/HW_ClickBanner_Feature-Highlight',
                                                            );
                                                        },
                                                    },
                                                }}
                                            >
                                                <GeneralButtonType
                                                    label={item.contentCtaText?.value}
                                                    icon='arrowRightBK'
                                                    // iconColor={themeColor}
                                                />
                                            </CustButton>
                                        )}
                                    </Flex>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <Flex vertical className={'h-full'}>
                                        <Image
                                            className='rounded-[5px]'
                                            preview={false}
                                            src={withFullPath(item.contentImage?.src)}
                                            alt=''
                                        />
                                    </Flex>
                                </Col>
                            </Row>
                        )}
                    </Slide>
                ))}
            </Slider>
        </Flex>
    );
};

export default HomePageContentSlider;
