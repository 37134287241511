'use client';
import { Flex, Row } from 'antd';
import Heading from '../Heading';
import CustButton, { GeneralButtonType } from '../Button';
import { HomePartyPromotionFragment, PartyInformationFragment } from '@/@types/generated/sitecore';
import AddToCalendar, { DummyData } from '@/components/AddToCalendar';
import { useGlobalStore } from '@/hooks/useGlobalStore';
import { WATrackerClickEvn, getButtonLinkWithCID } from '@/utils/wa';
import { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useTranslation } from '@/i18n/use-transition.client';
import useWindowSize from '@/utils/useScreenSize';
import { isTimeFrame, mobileBreakPoint, withFullPath } from '@/utils/utils';
import RichText from '../RichText';
import { CardSlideWithDate, Slider, SliderControlButton } from '../Slider';
import WaveRightIcon from '../SVG/WaveRightIcon';
import WaveLeftIcon from '../SVG/WaveLeftIcon';

interface Props {
    content: { Item: HomePartyPromotionFragment };
}

const HomePartyPromotion = (props: Props) => {
    const { parties, title, titleIconOptions } = props.content.Item;
    const [partyList, setPartyList] = useState<PartyInformationFragment[]>([]);

    const globalStore = useGlobalStore();
    const musicWaveColor = globalStore.currentTheme?.musicWaveColor;
    const TITLE_ICON = {
        titleIconRight: () => <WaveRightIcon iconColor={musicWaveColor}></WaveRightIcon>,
        titleIconLeft: () => <WaveLeftIcon iconColor={musicWaveColor}></WaveLeftIcon>,
    };

    const TitleIcon = titleIconOptions?.value
        ? TITLE_ICON[titleIconOptions?.value as keyof typeof TITLE_ICON]
        : () => null;

    const currentIndex =
        partyList?.findIndex((i) =>
            isTimeFrame(i?.startDatetime?.dateValue, i?.endDatetime?.dateValue),
        ) || 0;

    useEffect(() => {
        const currentDate = new Date().getTime();
        const partyItems = parties?.targetItems || [];
        const pastParties = partyItems.filter((party) => {
            return party?.endDatetime?.dateValue && party?.endDatetime?.dateValue < currentDate;
        });
        const current = partyItems.filter((party) =>
            isTimeFrame(party?.startDatetime?.dateValue, party?.endDatetime?.dateValue),
        );
        const futureParties = partyItems.filter((party: any) => {
            return party.startDatetime.dateValue && party.startDatetime.dateValue > currentDate;
        });

        pastParties.sort((a: any, b: any) => a.endDatetime.dateValue - b.endDatetime.dateValue);

        futureParties.sort(
            (a: any, b: any) => a.startDatetime.dateValue - b.startDatetime.dateValue,
        );

        setPartyList([...pastParties, ...current, ...futureParties]);
    }, [parties?.targetItems]);

    return (
        <Row align={'middle'} justify={'center'} className={'w-full py-24 xmd:px-[120px]'}>
            {title?.value && (
                <Flex vertical gap={20} justify='center' align='center'>
                    <Heading
                        className={'text-center text-base-font-color'}
                        level={3}
                        text={title?.value}
                    />
                    <TitleIcon />
                </Flex>
            )}
            <Slider
                slideTo={currentIndex > -1 ? currentIndex : 0}
                settings={{
                    loop: partyList.length > 3,
                    effect: 'coverflow',
                    centeredSlidesBounds: partyList.length > 3,
                    slidesPerView: 1.2,
                    coverflowEffect: {
                        rotate: 0,
                        stretch: 63,
                        depth: 300,
                        modifier: 1,
                        slideShadows: false,
                    },
                    autoplay: {
                        delay: 10000,
                        disableOnInteraction: false,
                    },
                    centeredSlides: true,
                    touchStartPreventDefault: true,
                    breakpoints: {
                        768: {
                            slidesPerView: 2.2,
                        },
                        1024: {
                            slidesPerView: 2.4,
                        },
                        1440: {
                            slidesPerView: 2.8,
                        },
                    },
                }}
                className='w-full mt-5'
            >
                {CardSlideWithDate({ data: partyList })}
                {partyList?.length > 1 && (
                    <SliderControlButton
                        onPrev={() => {
                            WATrackerClickEvn('RnE/HW_ClickBanner_Header-Banner-Left');
                        }}
                        onNext={() => {
                            WATrackerClickEvn('RnE/HW_ClickBanner_Header-Banner-Right');
                        }}
                    />
                )}
            </Slider>
        </Row>
    );
};

export default HomePartyPromotion;
