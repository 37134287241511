'use client';
import React from 'react';
import { Col, Flex, Row } from 'antd';
import cn from 'classnames';
import Heading from '@/components/Heading';
import Text from '@componentsText';
import { isRichText, withFullPath } from '@/utils/utils';
import TitleIconLeft from '@public/images/common/wave_icon_l.svg';
import CustButton, { GeneralButtonType } from '@/components/Button';
import RichText from '@/components/RichText';
import { useGlobalStore } from '@/hooks/useGlobalStore';

type ImageBgSectionProp = {
    image?: string;
    overlayColor?: string;
    children?: React.ReactNode;
    classname?: string;
};

type defaultTitleProp = {
    data: {
        title: string;
        description: string;
    };
};

type eventTitleProp = {
    data: {
        title: string;
        description: string;
        descriptionBg?: string;
        descriptionColor?: string;
    };
};

type headerTitle = {
    pageTitle: { value: string };
    pageDescription: { rendered: string };
};

export const DefaultPageHeader = ({ data }: defaultTitleProp) => (
    <Row gutter={[0, 20]} align={'middle'} justify={'space-between'} className='mt-32 mb-10'>
        <Col xs={24} lg={10}>
            {isRichText(data.title) ? (
                <RichText html={data.title || ''} />
            ) : (
                <Heading className='text-base-font-color' level={1} text={data.title} />
            )}
        </Col>
        <Col xs={24} lg={12}>
            {isRichText(data.description) ? (
                <RichText html={data.description || ''} />
            ) : (
                <Text level='body1' text={data.description} className='text-base-font-color' />
            )}
        </Col>
    </Row>
);

export const DefaultMobilePageHeaderTitle = ({ data }: defaultTitleProp) => (
    <div className='text-center mt-32 mb-10'>
        {isRichText(data.title) ? (
            <RichText html={data.title || ''} />
        ) : (
            <Heading className='text-base-font-color' level={1} text={data.title} />
        )}
    </div>
);

export const DefaultMobilePageHeaderContent = ({ data }: defaultTitleProp) => (
    <>
        <div className={'w-full'}>
            <div className={'py-6 mx-5 border-b border-content-divide-color'}>
                {isRichText(data.description) ? (
                    <RichText html={data.description || ''} />
                ) : (
                    <Text level='body1' text={data.description} className='text-base-font-color' />
                )}
            </div>
        </div>
    </>
);

export const PageHeaderT = ({ pageTitle, pageDescription }: headerTitle) => (
    <Row gutter={[0, 20]} align={'middle'} justify={'space-between'} className='mt-24 mb-10'>
        <Col xs={24} lg={10}>
            <Heading className='text-base-font-color' level={1} text={pageTitle?.value || ''} />
        </Col>

        <Col xs={24} lg={12}>
            <RichText className={'text-base-font-color'} html={pageDescription?.rendered || ''} />
        </Col>
    </Row>
);

export const EventPageHeader = ({ data }: eventTitleProp) => (
    <Flex vertical gap={16} align='center' className='mt-28 xmd:mt-36 mb-10'>
        <Heading className='text-base-font-color' level={2} text={data.title} />
        <Heading className='bg-[#EAE219] text-black w-fit' level={5} text={data.description} />
    </Flex>
);

export const GotAQuestion = ({ data }: any) => (
    <Flex gap={23} align='center' vertical className={cn('my-[100px]')}>
        <Flex vertical gap={8} align='center'>
            <Heading
                level={4}
                text={data.questionData.title}
                className='text-base-font-color text-center'
            />
            <TitleIconLeft />
        </Flex>
        <Text level='body2' text={data.questionData.subtitle} className='text-base-font-color' />
        <CustButton
            data={{
                buttonLink: data.questionData.btn.url,
            }}
        >
            <GeneralButtonType
                label={data.questionData.btn.label}
                icon={'arrowRightBK'}
                iconColor={''}
            />
        </CustButton>
    </Flex>
);

export const NextEvent = ({ data }: any) => (
    <Flex gap={23} align='center' vertical className={'my-24'}>
        <Flex vertical gap={8} align='center'>
            <Heading
                className={'text-center text-base-font-color'}
                level={5}
                text={data.ctaData.headline}
            />
            <TitleIconLeft />
        </Flex>
        <Heading
            className={'text-center text-base-font-color'}
            level={2}
            text={data.ctaData.title}
        />
        <Heading
            className={'text-center text-black bg-[#EAE219]'}
            level={5}
            text={data.ctaData.subtitle}
        />
        <CustButton
            data={{
                buttonLink: data.ctaData.btn.url,
                setting: {
                    type: 'link',
                },
            }}
        >
            <GeneralButtonType
                label={data.ctaData.btn.label}
                icon={'arrowRightPrimary'}
                iconColor={''}
                className='text-base-font-color'
            />
        </CustButton>
    </Flex>
);

const ImageBgSection = ({
    image = '',
    overlayColor = 'rgba(5, 25, 64, 0.80)',
    children,
    classname,
}: ImageBgSectionProp) => {
    const globalStore = useGlobalStore();
    const overlayBGColor = globalStore.currentTheme?.overlayBGColor;
    return (
        <Flex
            style={{ backgroundImage: `url(${withFullPath(image)})` }}
            align='center'
            justify='center'
            className={cn(
                'relative bg-cover bg-no-repeat bg-center w-full max-w-[1920px]',
                classname,
            )}
        >
            <div
                className={'absolute top-0 left-0 right-0 bottom-0'}
                style={{
                    backgroundColor: overlayBGColor || overlayColor,
                    opacity: 0.8,
                }}
            ></div>
            <div className={'max-w-[1920px] min-h-[200px] z-[1] w-full px-5 xmd:px-20'}>
                {children}
            </div>
        </Flex>
    );
};

export default ImageBgSection;
